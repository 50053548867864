import React from 'react'
import cx from 'classnames'
import { Layout } from 'antd'
import useMobile from 'shared/hooks/useMobile'
import { imageUrlPrefix } from 'shared/env'
import styled from 'styled-components'

export default function AppHeader() {
  const isMobile = useMobile()

  return (
    <Header className={cx({ 'is--mobile': isMobile })}>
      <img src={`${imageUrlPrefix}/logo.png`} className="mr-9" alt="logo" />
      臺中市養護工程處路燈維護管理系統
    </Header>
  )
}

const Header = styled(Layout.Header)`
  &.ant-layout-header {
    height: 52px;
    line-height: 52px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: 500;

    &.is--mobile {
      height: 46px;
      line-height: 46px;
      font-size: 14px;
    }
  }
`
