import React from 'react';
import styled from 'styled-components';
import { Row, Col, Icon } from 'antd';

type PropTypes = {
  className?: string;
  style?: React.CSSProperties;
};

const Wrapper = styled.div`
  .ant-spin {
    &--inner {
      margin: 0 auto;
    }
  }
`;

export default React.memo(function Loading({
  className,
  style,
  ...props
}: PropTypes) {
  return (
    <Wrapper>
      <Row type="flex" justify="center" className={className}>
        <Col className="ant-spin--inner">
          <Icon type="loading-3-quarters" spin theme="outlined" style={style} />
        </Col>
      </Row>
    </Wrapper>
  );
});
