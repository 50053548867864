import React, { Suspense } from 'react'
import { Layout } from 'antd'
import { Switch, Route } from 'react-router-dom'
import styled from 'styled-components'
import useMobile from 'shared/hooks/useMobile'
import ErrorBoundary from 'shared/components/ErrorBoundary'
import Loading from 'shared/components/Loading'
import SkeletonLoading from 'shared/components/SkeletonLoading'
import Exception from 'shared/components/Exception'
import AppFooter from 'src/components/AppFooter'
import AppHeader from 'src/components/AppHeader'
import SideMenu from 'src/components/SideMenu'
import Lights from 'src/views/Lights'
import Adoption from 'src/views/Adoptions'
import Repair from 'src/views/Repair'
import Links from 'src/views/Links'
import GovermentOpendata from 'src/views/GovermentOpendata'
import RepairInquiry from 'src/views/RepairInquiry'
import * as routes from 'src/constants/routes'
import GlobalStyles from './GlobalStyles'

const App: React.FC = () => {
  const isMobile = useMobile()

  return (
    <ErrorBoundary>
      <GlobalStyles />
      <AppHeader />
      <SideMenu />
      <Layout>
        <Content>
          <Suspense fallback={isMobile ? <SkeletonLoading /> : <Loading />}>
            <Switch>
              <Route component={Lights} path="/" exact />
              <Route component={Adoption} path={routes.ADOPTION} />
              <Route component={RepairInquiry} path={routes.REPAIR_INQUIRY} />
              <Route component={Repair} path={routes.NEW_REPAIR} />
              <Route component={Links} path={routes.LINKS} />
              <Route component={GovermentOpendata} path={routes.OPEN_DATA} />
              <Route component={() => <Exception type={404} />} />
            </Switch>
          </Suspense>
        </Content>
      </Layout>
      <AppFooter />
    </ErrorBoundary>
  )
}

const Content = styled(Layout.Content)`
  background: #fff;
`

export default App
