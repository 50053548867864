import React from 'react'
import cx from 'classnames'
import styled from 'styled-components'
import useMobile from 'shared/hooks/useMobile'
import { Layout } from 'antd'

export default function AppFooter() {
  const isMobile = useMobile()

  return (
    <Footer className={cx({ 'is--mobile': isMobile })}>
      <div className="mb-3">
        © 2019 427215 臺中市潭子區豐興路一段512號5樓 臺中市養護工程處
      </div>
      <div>洽詢路燈業務請至408009臺中市南屯區文心南五路一段331號2樓</div>
      <div>Tel：04-22289111轉34100</div>
      <div className="mb-3">
        服務時間：星期一至星期五 上午8:00-12:00、下午1:00-5:00
      </div>
      <div>
        本網站為臺中市政府版權所有，請尊重智慧財產權，未經允許請勿任意轉載、複製或做商業用途
      </div>
    </Footer>
  )
}

const Footer = styled(Layout.Footer)`
  &.ant-layout-footer {
    bottom: 0;
    width: 100%;
    padding-top: 70px;
    padding-bottom: 61px;
    font-size: 12px;
    color: ${p => p.theme.grey};
    letter-spacing: 0.13px;
    text-align: center;
    background: #fff;

    &.is--mobile {
      padding-bottom: 10px;
    }
  }
`
