import React from 'react'
import cx from 'classnames'
import Icon from './Icon'

export type Props = {
  className?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const EquipSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1">
        <path d="M19.285 7.859V1.05a.523.523 0 0 0-.524-.523H5.669L2.528 3.67v19.374c0 .29.234.524.524.524h15.709c.29 0 .524-.234.524-.524v-6.283" />
        <path d="M3.575 4.717h3.142V1.575M22.527 10.81l-1.851 1.852-1.482-1.481 1.852-1.851c-.74-.741-2.366-.597-3.333.37a3.13 3.13 0 0 0-.806 3.027l-5.884 5.885a1.569 1.569 0 0 0 0 2.221 1.568 1.568 0 0 0 2.22 0l5.886-5.884a3.126 3.126 0 0 0 3.027-.807c.968-.967 1.11-2.592.37-3.332zM6.193 7.859h8.38M6.193 9.954h8.38M6.193 12.048h4.713" />
      </g>
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
)

const MaintainSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g
        stroke="#7F7F7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1">
        <path d="M8.195 13.922v3.844M6.754 15.363l-1.301 1.301a.478.478 0 0 0-.141.34V18.4a.48.48 0 0 1-.302.446l-3.596 1.44a1.44 1.44 0 0 0-.907 1.337v1.427M6.754 17.285l3.072 1.317a.48.48 0 0 1 .291.44v2.567M11.078 15.363h-.39a2.841 2.841 0 0 1-1.953-.767s-1.292-1.138-1.561-1.534c-.269-.395-.421-1.564-.421-2.065v-2.84M13.96 13.922v3.844M15.401 17.285l-3.072 1.317a.481.481 0 0 0-.292.44v2.567M11.078 15.363h.39a2.84 2.84 0 0 0 1.952-.767s1.293-1.138 1.561-1.534c.269-.395.42-1.564.42-2.065v-2.84M10.117 4.794V1.43h1.92v3.363M17.323 8.157H4.832M8.675 3.833V1.911c-1.441.48-2.882 1.875-2.882 3.844v.48l-.481.48v.48M13.48 3.833V1.911c1.441.48 2.882 1.875 2.882 3.844v.48l.48.48v.48M22.22 16.15l-1.699 1.698-1.36-1.359 1.7-1.698c-.694-.693-2.154-.565-3.058.34-.657.657-.986 1.574-.598 2.557.135.34.029.73-.23.988l-2.872 2.873c-.339.337-.421.883-.141 1.272a.961.961 0 0 0 1.464.122l2.908-2.908c.259-.259.647-.365.987-.231.984.389 1.902.06 2.559-.597.904-.904 1.033-2.365.34-3.057z" />
      </g>
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
)

const MaterialSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g stroke="#7F7F7F" strokeWidth="1.1">
        <path strokeLinecap="round" d="M6.013 19.18h-3.29v3.291" />
        <path
          strokeLinecap="round"
          d="M22.533 7.367a11.351 11.351 0 0 1 1.035 4.68c.006 2.955-1.15 5.913-3.41 8.173-2.225 2.225-5.165 3.32-8.11 3.347-3.218.033-5.575-.95-7.68-2.742-.374-.316-1.646-1.646-1.646-1.646M1.563 16.73a11.351 11.351 0 0 1-1.035-4.68c-.006-2.956 1.15-5.913 3.41-8.172C6.163 1.65 9.103.558 12.048.529c3.218-.032 5.575.952 7.68 2.742.374.317 1.646 1.646 1.646 1.646"
        />
        <path strokeLinecap="round" d="M18.083 4.917h3.29V1.625" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.436 16.437l.549.548"
        />
        <path
          strokeLinejoin="round"
          d="M5.696 7.271L7.62 9.195a.548.548 0 0 0 .777 0l1.162-1.164a.547.547 0 0 0 0-.775L7.636 5.33a.55.55 0 0 1 .264-.925 4.112 4.112 0 0 1 4.896 4.926.558.558 0 0 0 .14.524l5.897 5.897c.655.656.505 1.822-.369 2.696-.873.874-2.079 1.063-2.735.407l-6.184-6.185a.558.558 0 0 0-.44-.154A4.102 4.102 0 0 1 4.77 7.536a.55.55 0 0 1 .926-.265z"
        />
      </g>
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
)

const ReportSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <g stroke="#7F7F7F" strokeWidth="1.1">
        <path d="M9.4 1.051h8.466l4.233 4.233v16.931c0 .584-.473 1.058-1.06 1.058H6.225a1.058 1.058 0 0 1-1.057-1.058V13.22" />
        <path d="M20.512 5.812h-3.175V2.637" />
        <path
          strokeLinejoin="round"
          d="M8.341 21.686v-5.82h2.117v5.82M12.575 21.686V19.04h2.116v2.645M16.807 21.686v-5.291h2.116v5.29M12.575 8.458h6.879M12.575 10.574h6.879M12.575 12.69h6.879M5.167 11.633V5.812H2.522L7.268.522l4.777 5.29H9.4v5.82z"
        />
      </g>
    </g>
  </svg>
)

const SystemSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <g stroke="#7F7F7F" strokeLinejoin="round" strokeWidth="1.1">
        <path d="M1.575 19.284H22.52c.58 0 1.048-.468 1.048-1.047V3.575c0-.578-.469-1.047-1.048-1.047H1.575c-.579 0-1.047.47-1.047 1.047v14.662c0 .579.468 1.047 1.047 1.047zM13.62 22.426v-3.141M10.477 19.284v3.142M16.76 22.426H7.337M23.568 16.142H.528M12.572 17.714h-1.048" />
        <path d="M16.861 6.526l-1.85 1.852-1.482-1.48 1.852-1.852c-.756-.755-2.347-.616-3.333.37-.716.716-1.075 1.716-.651 2.789.147.37.03.793-.252 1.076l-3.13 3.13c-.37.369-.458.963-.154 1.387.388.54 1.145.584 1.595.134l3.17-3.17c.283-.282.706-.398 1.077-.252 1.072.424 2.071.066 2.788-.651.986-.985 1.126-2.577.37-3.333z" />
      </g>
    </g>
  </svg>
)

const LinkSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <g stroke="#7F7F7F" strokeLinejoin="round" strokeWidth="1.1">
        <path d="M5.906 11.455l-4.27 4.27a3.623 3.623 0 0 0 0 5.122l1.709 1.708a3.623 3.623 0 0 0 5.122 0l6.403-6.403a3.621 3.621 0 0 0 0-5.123L13.163 9.32a3.66 3.66 0 0 0-.998-.707" />
        <path d="M18.286 12.736l4.27-4.268a3.624 3.624 0 0 0 0-5.124l-1.709-1.707a3.623 3.623 0 0 0-5.122 0L9.322 8.04a3.622 3.622 0 0 0 0 5.123l1.706 1.708c.3.299.638.535.999.708M5.724 5.504L3.309 3.09M5.12 7.315H1.498M7.535 1.278V4.9M19.006 18.182l2.415 2.415M19.61 16.37h3.622M17.195 22.408v-3.622" />
      </g>
    </g>
  </svg>
)

const EditSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24">
    <defs>
      <path
        id="a"
        d="M14 3.414l-10 10V16h2.586l10-10L14 3.414zm.707-2.121l4 4a1 1 0 0 1 0 1.414l-11 11A1 1 0 0 1 7 18H3a1 1 0 0 1-1-1v-4a1 1 0 0 1 .293-.707l11-11a1 1 0 0 1 1.414 0zM3 23a1 1 0 0 1 0-2h18a1 1 0 0 1 0 2H3z"
      />
    </defs>
    <use fill="#4A4A4A" fillRule="nonzero" xlinkHref="#a" />
  </svg>
)

const LocationSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="#4A4A4A"
        fillRule="nonzero"
        d="M12 5.337a4.71 4.71 0 0 0-3.347 1.385 4.701 4.701 0 0 0-1.385 3.347c0 1.265.492 2.451 1.385 3.347A4.701 4.701 0 0 0 12 14.8a4.705 4.705 0 0 0 3.347-1.385 4.701 4.701 0 0 0 1.385-3.347 4.705 4.705 0 0 0-1.385-3.347A4.71 4.71 0 0 0 12 5.337zm0 7.79a3.06 3.06 0 0 1-3.059-3.058A3.06 3.06 0 0 1 12 7.01a3.06 3.06 0 0 1 3.059 3.059A3.06 3.06 0 0 1 12 13.127zm8.405-6.808a8.216 8.216 0 0 0-2.009-2.553 9.572 9.572 0 0 0-2.916-1.715 9.913 9.913 0 0 0-3.478-.628h-.004c-1.191 0-2.36.21-3.478.628a9.572 9.572 0 0 0-2.916 1.715A8.209 8.209 0 0 0 3.595 6.32a6.995 6.995 0 0 0-.75 3.138c0 1.507.37 3.056 1.102 4.605.605 1.283 1.46 2.576 2.545 3.847 1.94 2.273 4.125 3.9 4.974 4.493a.927.927 0 0 0 1.068 0c.849-.593 3.036-2.22 4.974-4.493 1.083-1.27 1.94-2.564 2.545-3.846.731-1.55 1.102-3.101 1.102-4.606 0-1.085-.253-2.14-.75-3.138zm-.926 3.14c0 1.259-.316 2.57-.942 3.893-.537 1.144-1.313 2.311-2.302 3.471-1.544 1.812-3.274 3.197-4.235 3.903-.96-.708-2.69-2.09-4.235-3.903-.99-1.16-1.765-2.33-2.304-3.473-.626-1.324-.942-2.634-.942-3.893 0-.825.192-1.631.574-2.393A6.58 6.58 0 0 1 6.698 5.03a7.876 7.876 0 0 1 2.405-1.416 8.283 8.283 0 0 1 2.895-.523h.004c.99 0 1.964.176 2.893.523.897.335 1.706.811 2.407 1.416a6.47 6.47 0 0 1 1.603 2.034c.382.769.574 1.573.574 2.395z"
      />
    </g>
  </svg>
)

const EyeSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={24}
    height={24}
    viewBox="0 0 24 24">
    <defs>
      <path
        id="a"
        d="M22.157 11.798c-.007-.132-.209-.78-.49-1.456-.288-.696-.776-1.704-1.45-2.545a9.632 9.632 0 0 0-3.24-2.623C15.423 4.394 13.605 4 11.575 4c-2.027 0-3.846.396-5.4 1.177a9.695 9.695 0 0 0-3.24 2.62c-.674.844-1.16 1.851-1.448 2.548-.28.675-.48 1.324-.487 1.455V11.809c.007.132.209.78.49 1.458.29.696.776 1.704 1.448 2.543a9.632 9.632 0 0 0 3.24 2.623c1.563.783 3.379 1.179 5.4 1.179 2.022 0 3.838-.396 5.4-1.18a9.688 9.688 0 0 0 3.238-2.62c.675-.84 1.163-1.849 1.451-2.545.281-.675.483-1.324.49-1.455v-.005-.01zm-1.739.004c-.134.408-.652 1.88-1.51 2.949l-.011.011a8 8 0 0 1-2.679 2.17c-1.324.664-2.89 1.002-4.65 1.002-1.763 0-3.326-.338-4.65-1.001a7.963 7.963 0 0 1-2.679-2.173c-.862-1.073-1.383-2.55-1.516-2.96.133-.408.654-1.882 1.516-2.96A8.01 8.01 0 0 1 6.92 6.67c1.327-.664 2.893-.999 4.655-.999 1.77 0 3.333.335 4.648.999a7.963 7.963 0 0 1 2.679 2.172c.862 1.078 1.382 2.553 1.516 2.96zm-8.843-4.08a4.067 4.067 0 0 0-4.062 4.062 4.068 4.068 0 0 0 4.062 4.061 4.068 4.068 0 0 0 4.062-4.061 4.067 4.067 0 0 0-4.062-4.062zm2.39 4.062c0 .637-.248 1.237-.7 1.69-.453.452-1.053.7-1.69.7a2.374 2.374 0 0 1-1.69-.7 2.374 2.374 0 0 1-.7-1.69c0-.638.248-1.238.7-1.69a2.374 2.374 0 0 1 1.69-.701c.638 0 1.238.248 1.69.7.452.453.7 1.053.7 1.69z"
      />
    </defs>
    <use fill="#4A4A4A" fillRule="nonzero" xlinkHref="#a" />
  </svg>
)

const AddSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="#B68116"
        fillRule="nonzero"
        d="M20.156 18H16.5v-3.656a.843.843 0 1 0-1.688 0V18h-3.468a.843.843 0 1 0 0 1.688h3.469v3.468a.843.843 0 1 0 1.687 0v-3.468h3.656a.843.843 0 1 0 0-1.688zm.354-10.927L13.995.5a1.815 1.815 0 0 0-.347-.27 1.428 1.428 0 0 0-.103-.053c-.022-.012-.045-.021-.066-.03A1.696 1.696 0 0 0 12.797 0h-8.11C3.756 0 3 .755 3 1.688v20.625C3 23.244 3.755 24 4.688 24h4.968a.843.843 0 1 0 0-1.688H4.687V1.688H12v5.625C12 8.245 12.755 9 13.688 9h5.624v3.656a.843.843 0 1 0 1.688 0V8.262c0-.446-.176-.872-.49-1.189zm-6.823.24V2.588l4.683 4.724h-4.683z"
      />
    </g>
  </svg>
)

const ExportSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="#B68116"
        fillRule="nonzero"
        d="M18.916 13.669a.84.84 0 0 0-.839.839v4.779a.435.435 0 0 1-.436.436H4.352a.435.435 0 0 1-.436-.436V5.527c0-.242.195-.436.436-.436h5.968a.84.84 0 0 0 0-1.678H4.355a2.114 2.114 0 0 0-2.112 2.111v13.76c0 1.163.947 2.11 2.112 2.11h13.291a2.114 2.114 0 0 0 2.112-2.112v-4.774a.845.845 0 0 0-.842-.84zm-8.76-4.927c-1.008 1.524-1.254 3.021-1.278 3.188a.84.84 0 0 0 .83.958c.412 0 .769-.31.83-.712.007-.056.22-1.322 1.042-2.543 1.08-1.603 2.705-2.421 4.82-2.428.032.002.06.004.084.004a.84.84 0 0 0 .839-.839v-.986l2.355 2.24-2.358 1.964v-.801a.84.84 0 0 0-1.678 0v2.592a.842.842 0 0 0 1.374.647l4.455-3.71a.837.837 0 0 0 .298-.617.838.838 0 0 0-.258-.637l-4.453-4.24a.837.837 0 0 0-1.413.607v2.13c-2.947.246-4.61 1.861-5.49 3.183z"
      />
    </g>
  </svg>
)

const ProcessSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="#4A4A4A"
        fillRule="nonzero"
        d="M3.809 13.688h8.882c.446 0 .809-.361.809-.81v-.07a.807.807 0 0 0-.809-.808H3.81a.809.809 0 0 0-.809.809v.07c0 .448.36.809.809.809zm8.789 1.08a.842.842 0 0 0 0 1.193l1.99 1.99.02.02.022.022.082.082a.785.785 0 0 0 1.11 0l5.25-5.255a.785.785 0 0 0 0-1.11l-.081-.083a.785.785 0 0 0-1.111 0l-4.615 4.615-1.474-1.474a.842.842 0 0 0-1.193 0zM22.312 4.5h-1.968v-.656a.843.843 0 1 0-1.688 0V4.5h-5.812v-.656a.843.843 0 1 0-1.688 0V4.5H5.344v-.656a.843.843 0 1 0-1.688 0V4.5H1.687C.755 4.5 0 5.255 0 6.188v13.125C0 20.244.755 21 1.688 21h20.625c.932 0 1.687-.755 1.687-1.688V6.188c0-.932-.755-1.687-1.688-1.687zm0 14.813H1.688V10.5h20.625v8.813zm0-10.5H1.688V6.187h1.97v.47a.843.843 0 1 0 1.687 0v-.47h5.812v.47a.843.843 0 1 0 1.688 0v-.47h5.812v.47a.843.843 0 1 0 1.688 0v-.47h1.968v2.625zM3.81 16.593H9.69c.446 0 .809-.36.809-.808v-.07a.807.807 0 0 0-.809-.809H3.81a.809.809 0 0 0-.809.809v.07c0 .448.36.809.809.809z"
      />
    </g>
  </svg>
)

const InfoSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path d="M.023 0H24v23.977H.023z" />
      <path
        fill="#4A4A4A"
        fillRule="nonzero"
        d="M22.478 9.75V6.93c0-.931-.754-1.685-1.686-1.685H11.45l-.586-1.824a1.682 1.682 0 0 0-1.606-1.173H1.686C.754 2.248 0 3.002 0 3.934V20.05a1.68 1.68 0 0 0 .021.253c.005.023.007.047.012.07l.004.019.015.058c.002.012.007.024.009.035l.007.029c.007.025.014.049.023.072v.002c.087.253.232.478.422.66l.004.005c.02.017.036.033.054.05.01.007.019.016.026.023l.026.021.047.038c.004.002.009.007.014.009l.058.042c.003 0 .003.002.005.002a1.657 1.657 0 0 0 .773.28c.025.001.049.004.075.004h.014l.077.002h18.45a1.69 1.69 0 0 0 1.637-1.276l2.154-8.617a1.689 1.689 0 0 0-1.449-2.081zM1.686 3.934h7.567l.965 2.997h10.574v2.81H3.84a1.69 1.69 0 0 0-1.637 1.276l-.517 2.079V3.934zm18.45 16.109H1.687l2.154-8.617h18.45l-2.153 8.617z"
      />
    </g>
  </svg>
)

export const IconEquip: React.FC<Props> = ({ className, ...props }) => (
  <Icon
    {...props}
    component={EquipSVG}
    className={cx('icon-equip', className)}
  />
)

export const IconMaintain: React.FC<Props> = ({ className, ...props }) => (
  <Icon
    {...props}
    component={MaintainSVG}
    className={cx('icon-maintain', className)}
  />
)

export const IconMaterial: React.FC<Props> = ({ className, ...props }) => (
  <Icon
    {...props}
    component={MaterialSVG}
    className={cx('icon-material', className)}
  />
)

export const IconReport: React.FC<Props> = ({ className, ...props }) => (
  <Icon
    {...props}
    component={ReportSVG}
    className={cx('icon-report', className)}
  />
)

export const IconSystem: React.FC<Props> = ({ className, ...props }) => (
  <Icon
    {...props}
    component={SystemSVG}
    className={cx('icon-system', className)}
  />
)

export const IconLink: React.FC<Props> = ({ className, ...props }) => (
  <Icon {...props} component={LinkSVG} className={cx('icon-link', className)} />
)

export const IconLocation: React.FC<Props> = ({ className, ...props }) => (
  <Icon
    {...props}
    component={LocationSVG}
    className={cx('icon-location', className)}
  />
)

export const IconEye: React.FC<Props> = ({ className, ...props }) => (
  <Icon {...props} component={EyeSVG} className={cx('icon-eye', className)} />
)

export const IconEdit: React.FC<Props> = ({ className, ...props }) => (
  <Icon {...props} component={EditSVG} className={cx('icon-edit', className)} />
)

export const IconAdd: React.FC<Props> = ({ className, ...props }) => (
  <Icon {...props} component={AddSVG} className={cx('icon-add', className)} />
)

export const IconExport: React.FC<Props> = ({ className, ...props }) => (
  <Icon
    {...props}
    component={ExportSVG}
    className={cx('icon-export', className)}
  />
)

export const IconImport: React.FC<Props> = ({ className, ...props }) => (
  <Icon type="import" {...props} />
)

export const IconProcess: React.FC<Props> = ({ className, ...props }) => (
  <Icon
    {...props}
    component={ProcessSVG}
    className={cx('icon-process', className)}
  />
)

export const IconInfo: React.FC<Props> = ({ className, ...props }) => (
  <Icon {...props} component={InfoSVG} className={cx('icon-info', className)} />
)

export const IconLoading: React.FC<Props> = ({ className, ...props }) => (
  <Icon
    {...props}
    type="loading-3-quarters"
    spin
    className={cx('icon-loading', className)}
  />
)
