import React from 'react'
import cx from 'classnames'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { Layout, Row, Col } from 'antd'
import useMobile from 'shared/hooks/useMobile'
import Button from 'shared/components/Button'
import history from 'shared/utils/history'
import * as routes from 'src/constants/routes'
import {
  IconGroup,
  IconInfo,
  IconProgress,
  IconShape,
  IconWeb,
  Iconhandshake,
} from 'src/components/icons'

export default function SideMenu() {
  const { pathname } = useLocation()

  const isMobile = useMobile()

  const handleTo = (pathname: string) => () => {
    history.push(pathname)
  }

  return (
    <Header className={cx({ 'is--mobile': isMobile })}>
      <Row className={cx({ 'is--scrollable': isMobile })}>
        <Col className={cx({ 'is--scrollable': isMobile })}>
          <LinkButton
            icon={IconShape as any}
            size={isMobile ? 'default' : 'large'}
            className={cx({
              'is--active': pathname === '/',
              'is--mobile': isMobile,
            })}
            onClick={handleTo('/')}>
            路燈資訊
          </LinkButton>
          <LinkButton
            icon={Iconhandshake as any}
            size={isMobile ? 'default' : 'large'}
            className={cx({
              'is--active': pathname === routes.ADOPTION,
              'is--mobile': isMobile,
            })}
            onClick={handleTo(routes.ADOPTION)}>
            路燈認養
          </LinkButton>
          <LinkButton
            icon={IconWeb as any}
            size={isMobile ? 'default' : 'large'}
            className={cx({
              'is--active': pathname === routes.NEW_REPAIR,
              'is--mobile': isMobile,
            })}
            onClick={handleTo(routes.NEW_REPAIR)}>
            路燈報修
          </LinkButton>
          <LinkButton
            icon={IconProgress as any}
            size={isMobile ? 'default' : 'large'}
            className={cx({
              'is--active': pathname === routes.REPAIR_INQUIRY,
              'is--mobile': isMobile,
            })}
            onClick={handleTo(routes.REPAIR_INQUIRY)}>
            維修進度查詢
          </LinkButton>
          <LinkButton
            icon={IconInfo as any}
            size={isMobile ? 'default' : 'large'}
            className={cx({
              'is--active': pathname === routes.OPEN_DATA,
              'is--mobile': isMobile,
            })}
            onClick={handleTo(routes.OPEN_DATA)}>
            政府開放資料
          </LinkButton>
          <LinkButton
            icon={IconGroup as any}
            size={isMobile ? 'default' : 'large'}
            className={cx({
              'is--active': pathname === routes.LINKS,
              'is--mobile': isMobile,
            })}
            onClick={handleTo(routes.LINKS)}>
            相關連結
          </LinkButton>
        </Col>
      </Row>
    </Header>
  )
}

const LinkButton = styled(Button).attrs({ circle: 'true' })`
  &.ant-btn {
    min-width: 150px;
    margin-right: 22px;
    background: ${p => p.theme.lightGrey};
    color: ${p => p.theme.dark};

    &:focus,
    &:active,
    &:hover,
    &.is--active {
      background: #f5af23;
      border-color: #f5af23;
      color: ${p => p.theme.dark};
    }

    > div {
      justify-content: center;
    }

    &.is--mobile {
      min-width: 130px;
      margin-right: 15px;
    }
  }
`

const Header = styled(Layout.Header)`
  &.ant-layout-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 70px;
    text-align: center;
    padding-top: 10px;
    background: ${p => p.theme.darkGrey};

    &.is--mobile {
      height: 54px;
      line-height: 54px;
      padding-top: 9px;
      padding-left: 8px;
      padding-right: 8px;
      width: 100%;
    }
  }

  .is--scrollable {
    width: 900px;
    overflow-x: scroll;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`
