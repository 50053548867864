import React from 'react'
import styled, { css } from 'styled-components'
import { message as antMessage } from 'antd'
import { Toast } from 'antd-mobile'
import { ConfigOptions } from 'antd/lib/message'
import { compose, is, not } from 'ramda'
import theme from '../theme'
import Icon from '../components/Icon'
import { isMobile } from '../env'

type MessageProps = { children?: React.ReactNode; type?: string }

export const error = (error: any) => {
  isMobile()
    ? Toast.fail(error.message || (error as any).msg || '伺服器錯誤', 2)
    : baseMessage({
        content: error.message || (error as any).msg || '伺服器錯誤',
        type: 'error',
        maxCount: 1,
        duration: 3,
      })
}

export const message = (
  content: string | React.ReactNode,
  type: 'loading' | 'success' | 'fail' | 'error',
) => {
  isMobile()
    ? Toast[type === 'error' ? 'fail' : type](content, 2)
    : baseMessage({
        content,
        type: type === 'fail' ? 'error' : type,
        maxCount: 1,
        duration: 3,
      })
}

export const destroy = antMessage.destroy

const MessageWrapper = styled.div<MessageProps>`
  position: relative;
  width: 560px;
  margin: -10px -16px;
  padding: 20px 10px 60px 16px;
  text-align: left;
  font-size: 14px;
  color: ${theme.dark};

  ${p =>
    p.type === 'success' &&
    css`
      border: 1px solid #91d5ff;
      background: #e6f7ff;
    `};

  ${p =>
    p.type === 'error' &&
    css`
      border: 1px solid #d74c4c;
      background: #ffeaea;
    `};

  ${p =>
    p.type === 'warning' &&
    css`
      border: 1px solid #ffa39e;
      background: #fff1f0;
    `};

  .anticon-info-circle > svg > path {
    fill: #1890ff;
  }

  .anticon-warning > svg > path {
    fill: #f34c39;
  }

  .btn {
    float: right;
    cursor: pointer;

    &--close {
      position: absolute;
      top: 5px;
      right: 5px;

      .anticon-close > svg > path {
        fill: #222;
      }
    }
  }
`

function Message(props: MessageProps) {
  return <MessageWrapper type={props.type}>{props.children}</MessageWrapper>
}

type Props = ConfigOptions & {
  content?: string | React.ReactNode
  // config?: ConfigOptions
  type?: 'info' | 'success' | 'error' | 'warning' | 'loading'
  onClose?: () => any
}

const baseMessage = ({
  content,
  type = 'success',
  onClose,
  maxCount,
  top,
  duration = 3,
}: Props) => {
  antMessage.config({ maxCount, top })

  const handleDismiss = () => {
    antMessage.destroy()

    if (onClose) {
      onClose()
    }
  }

  return antMessage.open({
    content: (
      <Message type={type}>
        {type === 'success' && <Icon type="info-circle" theme="filled" />}
        {type === 'error' && <Icon type="close-circle" theme="filled" />}
        {type === 'warning' && <Icon type="warning" theme="filled" />}
        {compose(not, is(String))(content) ? (
          content
        ) : (
          <span dangerouslySetInnerHTML={{ __html: content as any }} />
        )}
        <div className="btn btn--close">
          <Icon type="close" onClick={handleDismiss} />
        </div>
      </Message>
    ) as React.ReactNode,
    duration,
    type: null as any,
  })
}

export default baseMessage
