import React from 'react'
import cx from 'classnames'
import Icon from 'shared/components/Icon'
import { Props } from 'shared/components/Icons'

const HandshakeSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={20}
    viewBox="0 0 28 20">
    <path
      fill="#000"
      fillRule="nonzero"
      d="M27.01.02h-3.314a.815.815 0 0 0-.816.815v.82C12.2 1.648 18.228 1.65 4.946 1.65V.835A.815.815 0 0 0 4.13.019H.815A.815.815 0 0 0 0 .835v14.711c0 .45.365.815.815.815H4.13c.45 0 .816-.365.816-.815v-.78L7.43 18.82a2.445 2.445 0 0 0 2.908 1.017 2.431 2.431 0 0 0 1.342-1.187 2.435 2.435 0 0 0 3.128-1.132 2.444 2.444 0 0 0 3.13-1.133 2.411 2.411 0 0 0 3.283-1.653h1.66v.815c0 .45.364.815.815.815h3.315c.45 0 .815-.365.815-.815V.835a.815.815 0 0 0-.815-.816zM3.316 14.73H1.63V1.65h1.685v13.08zm16.3-.311a.787.787 0 0 1-1.41.152l-2.014-3.278a.815.815 0 1 0-1.39.854l1.257 2.045.4.65a.812.812 0 0 1-.423 1.192.816.816 0 0 1-.97-.34l-1.997-3.256a.815.815 0 1 0-1.39.852l1.648 2.689a.814.814 0 0 1-1.385.85l-1.995-3.248a.815.815 0 0 0-1.39.853c1.381 2.248 1.64 2.678 1.64 2.678a.813.813 0 0 1-.414 1.191.809.809 0 0 1-.962-.336L6.075 13.49a.815.815 0 0 0-.695-.39h-.434V3.28h6.073l-.62.286a2.636 2.636 0 0 0 2.126 4.823l3.001-1.26 4.02 6.623a.775.775 0 0 1 .07.667zm1.43-1.319c-.078-.159.257.403-4.483-7.408a.815.815 0 0 0-1.03-.334l-3.638 1.528a1.005 1.005 0 0 1-.81-1.84l3.823-1.766 7.973.006V13.1h-1.835zm5.15 1.63h-1.684V1.65h1.685v13.08z"
    />
  </svg>
)

const InfoSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20">
    <path
      fill="#030104"
      fillRule="nonzero"
      d="M9.843 0C4.407 0 0 4.407 0 9.843s4.407 9.845 9.843 9.845 9.845-4.409 9.845-9.845C19.688 4.407 15.279 0 9.842 0zm2.05 15.256c-.507.2-.91.351-1.213.457a3.199 3.199 0 0 1-1.052.157c-.613 0-1.09-.15-1.43-.45a1.45 1.45 0 0 1-.51-1.138c0-.18.013-.363.038-.55.026-.186.067-.396.122-.632l.635-2.24c.055-.215.104-.42.142-.61.038-.19.057-.367.057-.527 0-.285-.06-.485-.177-.597-.12-.113-.343-.168-.678-.168-.163 0-.331.024-.504.075-.17.053-.319.1-.44.147l.167-.69c.415-.17.813-.314 1.192-.434a3.52 3.52 0 0 1 1.075-.182c.609 0 1.079.149 1.41.442.329.294.495.676.495 1.146 0 .098-.012.27-.034.515a3.44 3.44 0 0 1-.127.675l-.631 2.234c-.052.179-.098.384-.14.613-.04.23-.06.404-.06.522 0 .296.066.499.2.606.13.108.362.162.688.162.155 0 .327-.027.522-.08.193-.054.333-.101.422-.142l-.17.689zM11.78 6.19c-.294.273-.649.41-1.063.41-.413 0-.77-.137-1.066-.41a1.309 1.309 0 0 1-.444-.994c0-.388.15-.721.444-.997a1.51 1.51 0 0 1 1.066-.414c.415 0 .77.137 1.063.414.294.276.441.61.441.997 0 .389-.147.72-.441.994z"
    />
  </svg>
)

const ProgressSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20">
    <g fill="#000" fillRule="nonzero">
      <path d="M12.644 7.373a.587.587 0 0 0-.83 0l-4.44 4.442a.586.586 0 1 0 .829.83l4.441-4.442a.587.587 0 0 0 0-.83zM11.37 11.37a.59.59 0 0 0 0 .83.59.59 0 0 0 .83 0 .59.59 0 0 0 0-.83.59.59 0 0 0-.83 0zM8.647 8.647a.59.59 0 0 0 0-.83.588.588 0 0 0-.83 0 .59.59 0 0 0 0 .83.59.59 0 0 0 .83 0zM5.52 18.956c1.213.609 2.542.964 3.902 1.044v-4.327a5.69 5.69 0 0 1-1.736-.468L5.52 18.956zM15.674 9.422H20a9.939 9.939 0 0 0-1.04-3.904l-3.752 2.166c.242.54.403 1.125.466 1.738zM15.208 12.333l3.751 2.166A9.936 9.936 0 0 0 20 10.595h-4.326a5.65 5.65 0 0 1-.466 1.738zM14.62 6.67l3.75-2.166a10.104 10.104 0 0 0-2.856-2.857l-2.166 3.751c.487.354.917.784 1.271 1.272zM13.348 14.62l2.166 3.75a10.098 10.098 0 0 0 2.857-2.856l-3.752-2.166a5.75 5.75 0 0 1-1.271 1.271z" />
      <path d="M5.076 12.856a5.693 5.693 0 0 1-.762-2.847 5.701 5.701 0 0 1 5.695-5.695c.827 0 1.614.177 2.324.496L14.5 1.058A9.968 9.968 0 0 0 10.01 0a9.943 9.943 0 0 0-7.077 2.932A9.943 9.943 0 0 0 0 10.009c0 1.76.463 3.49 1.34 5.005v.001a10.068 10.068 0 0 0 3.165 3.353l2.165-3.75a5.698 5.698 0 0 1-1.594-1.762zM2.079 13.91a8.843 8.843 0 0 1-.906-3.901c0-4.675 3.65-8.514 8.25-8.817v1.974c-3.514.298-6.282 3.253-6.282 6.843 0 1.01.222 2.006.647 2.914l-1.709.987zM10.595 15.674V20a9.935 9.935 0 0 0 3.904-1.04l-2.166-3.753a5.646 5.646 0 0 1-1.738.467z" />
    </g>
  </svg>
)

const ShapeSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24">
    <path
      fill="#000"
      fillRule="nonzero"
      d="M16.219 0C14.433 0 12.858.956 12 2.39A4.914 4.914 0 0 0 7.781 0H0v.703a3.517 3.517 0 0 0 6.96.703h.821a3.517 3.517 0 0 1 3.516 3.516v9.234h1.406V4.922a3.517 3.517 0 0 1 3.516-3.516h.82A3.516 3.516 0 0 0 24 .703V0h-7.781zm-2.18 15.563H9.961l-.703 7.03h-2.18V24h9.844v-1.406h-2.18l-.703-7.032z"
    />
  </svg>
)

const WebSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20">
    <g fill="#000" fillRule="nonzero">
      <path d="M14.628 10.96a.553.553 0 0 0-.8 0l-1.201 1.242-.495-.512-.801.828.495.513-1.203 1.244a.6.6 0 0 0 0 .828l3.87 4.003a2.767 2.767 0 0 0 4.009-.002c1.103-1.143 1.102-3.003-.003-4.145l-3.87-3.999zm2.233 6.45a.553.553 0 0 1-.801 0l-2.229-2.305a.6.6 0 0 1 0-.829.553.553 0 0 1 .802 0l2.228 2.305a.6.6 0 0 1 0 .829z" />
      <path d="M14.461 7.623c2.783.835 4.894-.618 4.894-3.724 0-.53-.084-1.05-.251-1.546a.559.559 0 0 0-.936-.221l-1.624 1.68h-.598l-.261-.271c.005-.202.015-.371.02-.59l1.646-1.702c.292-.303.182-.818-.207-.965A4.395 4.395 0 0 0 15.586 0c-3.04 0-4.435 2.242-3.584 5.124l-7.029 7.27C2.183 11.513.02 12.96.02 16.1c0 .554.092 1.096.274 1.612.142.402.64.516.933.213l1.642-1.698h.597l.239.246v.618l-1.625 1.68c-.295.305-.18.825.214.967.48.173.983.261 1.495.261 2.997 0 4.41-2.177 3.6-5.062l7.072-7.315z" />
      <path d="M1.935 4.52c.107.109.25.17.4.17h1.428L7.29 8.34l.8-.83-3.508-3.628V2.445a.597.597 0 0 0-.166-.415L2.654.207a.553.553 0 0 0-.8 0L.21 1.907a.6.6 0 0 0 0 .828L1.935 4.52z" />
    </g>
  </svg>
)

const GroupSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    viewBox="0 0 21 20">
    <g fill="#222" fillRule="nonzero">
      <path d="M12.267 7.782a4.705 4.705 0 0 0-6.634 0L1.37 12.029A4.661 4.661 0 0 0 0 15.326c-.002 2.58 2.097 4.672 4.688 4.674a4.67 4.67 0 0 0 3.314-1.361l3.518-3.504a.333.333 0 0 0-.238-.57h-.134a5.66 5.66 0 0 1-2.145-.414.336.336 0 0 0-.365.073l-2.53 2.523a2.017 2.017 0 0 1-2.844 0 1.997 1.997 0 0 1 0-2.833l4.279-4.257a2.016 2.016 0 0 1 2.84 0 1.378 1.378 0 0 0 1.884 0c.227-.227.365-.528.389-.848a1.332 1.332 0 0 0-.39-1.027z" />
      <path d="M18.711 1.368a4.7 4.7 0 0 0-6.63 0L8.567 4.865a.335.335 0 0 0 .244.57h.124c.734 0 1.46.14 2.14.417.125.052.27.023.365-.073l2.521-2.509a2.016 2.016 0 0 1 2.844 0c.785.782.785 2.05 0 2.833l-3.142 3.126-.026.03-1.099 1.088c-.784.78-2.055.78-2.84 0a1.377 1.377 0 0 0-1.882 0 1.333 1.333 0 0 0-.388.854c-.025.382.117.756.388 1.028.388.387.841.704 1.34.934.07.033.14.06.21.09.071.03.145.053.215.08.07.027.144.05.214.07l.198.053c.134.034.268.06.405.084.166.024.332.04.5.046h.254l.2-.023c.074-.003.151-.02.238-.02h.114l.231-.033.108-.02.194-.04h.037a4.692 4.692 0 0 0 2.173-1.228l4.263-4.247a4.66 4.66 0 0 0 0-6.607z" />
    </g>
  </svg>
)

export const Iconhandshake: React.FC<Props> = ({ className, ...props }) => (
  <Icon
    {...props}
    component={HandshakeSVG}
    className={cx('icon-handshake', className)}
  />
)

export const IconInfo: React.FC<Props> = ({ className, ...props }) => (
  <Icon {...props} component={InfoSVG} className={cx('icon-info', className)} />
)

export const IconProgress: React.FC<Props> = ({ className, ...props }) => (
  <Icon
    {...props}
    component={ProgressSVG}
    className={cx('icon-progress', className)}
  />
)

export const IconShape: React.FC<Props> = ({ className, ...props }) => (
  <Icon
    {...props}
    component={ShapeSVG}
    className={cx('icon-shape', className)}
  />
)

export const IconWeb: React.FC<Props> = ({ className, ...props }) => (
  <Icon {...props} component={WebSVG} className={cx('icon-web', className)} />
)

export const IconGroup: React.FC<Props> = ({ className, ...props }) => (
  <Icon
    {...props}
    component={GroupSVG}
    className={cx('icon-group', className)}
  />
)
