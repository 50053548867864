export const MARGINS = [
  0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 18, 20, 22, 24, 25, 26, 28,
  29, 30, 31, 32, 34, 35, 36, 40, 42, 43, 44, 45, 50, 52, 60, 63, 64, 75, 78,
  80, 85, 87, 88, 90, 96, 100, 104, 106, 110, 114, 120, 128, 136, 144, 150, 156,
  160, 164, 167, 169, 172, 175, 178, 180, 184, 186, 190, 196, 210, 220, 229,
  231, 240, 250, 260, 272, 280, 294, 300, 360,
]

export const DIRECTS: {
  [key: string]: string
} = {
  mt: 'margin-top',
  mb: 'margin-bottom',
  ml: 'margin-left',
  mr: 'margin-right',
  pt: 'padding-top',
  pb: 'padding-bottom',
  pl: 'padding-left',
  pr: 'padding-right',
}
