export type Theme = {
  [key: string]: any
}

const theme: Theme = {
  primary: '#ffc247',
  secondary: '#b68116',
  info: '#4a90e2',
  warning: '#fff1b8',
  danger: '#d74c4c',
  dark: '#222',
  lightDark: '#333',
  darkGrey: '#4a4a4a',
  grey: '#7f7f7f',
  lightGrey: '#d8d8d8',
  lightPink: '#ffeaea',
}

export default theme
