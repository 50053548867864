import React from 'react'
import 'shared/bootstrap'
import ReactDOM from 'react-dom'
import { shouldRedirect } from 'shared/utils/webHelper'
import renderer from 'shared/utils/renderer'
import CheckBrowserSupport from 'shared/utils/CheckBrowserSupport'
import { envStore, isMobile, citizenApiBaseUrl } from 'shared/env'
import App from './App'
import * as serviceWorker from './serviceWorker'

// If the pathname is `/` then redirect to `/SCTaiChung/lightMaintain/citizen`
shouldRedirect()

envStore.setPageSize = isMobile() ? 10 : 15

envStore.apiBaseUrl = citizenApiBaseUrl

const app = renderer(App)

ReactDOM.render(
  <>
    <CheckBrowserSupport />
    {app}
  </>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
