import {
  find,
  propEq,
  forEach,
  addIndex,
  has,
  map,
  isEmpty,
  ifElse,
  identity,
  either,
  not,
  is,
  isNil as RisNil,
  path as Rpath,
  pathOr as RpathOr,
  head,
  filter,
  allPass,
  cond,
  T,
  keys,
  invoker,
  __,
} from 'ramda'
import moment from 'moment'
import Store from 'store'
import { appUrl, isMobile, apiBaseUrl } from '../env'
const { compose, concat } = require('ramda')

export const shouldRedirect = () => {
  if (window.location.pathname === '/') {
    window.location.href = appUrl
    return
  }
}

export function queryString(search: string) {
  if (!search) {
    search = window.location.search
  }

  const params = new URLSearchParams(search)

  let result: { [key: string]: string } = {}

  params.forEach((value: string, key: string) => (result[key] = value))

  return result
}

export function uuid() {
  let d = Date.now()
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now()
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export function isUUID(uuid: string) {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    uuid
  )
}

export function toBase64(file: File) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.addEventListener('load', () => resolve(fileReader.result))
    fileReader.onerror = reject
    fileReader.readAsDataURL(file)
  })
}

export function selectFile({
  accept = '*',
  callback,
}: {
  accept?: string
  callback: (evt: Event) => any
}) {
  const input = document.createElement('input')
  input.setAttribute('type', 'file')
  input.setAttribute('class', 'hidden')
  input.setAttribute('accept', accept)
  input.addEventListener('change', callback)
  input.click()
}

export const findValue = (value?: number | string, field = 'pid') =>
  find(propEq(field, value))

export const forEachIndexed = addIndex(forEach)
export const mapIndexed: any = addIndex(map)
export const filterIndexed: any = addIndex(filter)

export const isNil = either(isEmpty, RisNil)

export const hasValue = ifElse(isNil, identity, has('value'))

export const hasToken = ifElse(isNil, identity, has('token'))

export const hasPath = (path: Array<string | number>) =>
  compose(not, isNil, Rpath(path))

export const getPickerValue = ifElse(is(Array), head, identity)

export const pathOr = (
  defaultValue: any,
  [first, ...other]: (number | string)[],
  obj?: any
) => {
  if (!obj) {
    return (obj: any) =>
      RpathOr(RpathOr(defaultValue, [String(first), ...other])(obj), [
        first,
        ...other,
      ])(obj)
  }

  return RpathOr(RpathOr(defaultValue, [String(first), ...other])(obj), [
    first,
    ...other,
  ])(obj)
}

export const toMoment = (str: moment.Moment | string | undefined) => {
  const format = 'YYYY-MM-DDTHH:mm:ssZ'

  const converter = isMobile() ? () => moment(str, format) : () => moment(str)

  if (!str) {
    return undefined
  }

  if (!converter().isValid()) {
    return undefined
  }

  if (moment.isMoment(str)) {
    return str
  }

  return converter()
}

export const momentToString = (
  date: moment.Moment | undefined | string,
  format = 'YYYY/MM/DD HH:mm:ss'
) => {
  if (!date) {
    return date as any
  }

  return moment.isMoment(date)
    ? date.format(format)
    : is(String, date) || is(Date, date)
    ? toMoment(date)
      ? (toMoment(date) as moment.Moment).format(format)
      : ''
    : ''
}

export const momentToISOString = (
  date: moment.Moment | undefined | string | Date
) => {
  if (moment.isMoment(date)) {
    return date.toISOString()
  }

  if (date instanceof Date) {
    const [yyyy, M, dd, hh, mm, ss] = getDatePart(date)
    return `${yyyy}-${M}-${dd}T${hh}:${mm}:${ss}Z`
  }

  if (is(String, date) && toMoment(date)) {
    return (toMoment(date) as moment.Moment).toISOString()
  }

  return ''
}

export const getRangeDate = (
  date: [moment.Moment, moment.Moment],
  dateFormat?: string
) => {
  const [startDate, stopDate] = date

  const setDateTime = (unit: moment.MomentSetObject) => (m: moment.Moment) => {
    let _m: any = m.clone()

    compose(
      forEach((key: keyof moment.MomentSetObject) => _m.set(key, unit[key])),
      keys
    )(unit)

    return _m
  }

  const getDateString = (unit: moment.MomentSetObject) =>
    cond([
      [
        allPass([compose(not, isNil), () => !isNil(dateFormat)]),
        compose(invoker(1, 'format')(dateFormat), setDateTime(unit)),
      ],
      [
        allPass([compose(not, isNil), () => isNil(dateFormat)]),
        compose(
          concat(__, 'Z'),
          invoker(1, 'format')('YYYY-MM-DDTHH:mm:ss.SSS'),
          setDateTime(unit)
        ),
      ],
      [T, identity],
    ])

  const start = getDateString({
    hour: 0,
    minute: 0,
    second: 0,
    milliseconds: 0,
  })(startDate)

  const end = getDateString({
    hour: 23,
    minute: 59,
    second: 59,
    milliseconds: 999,
  })(stopDate)

  return [start, end]
}

export const toThousandSeparator = (number: number | string) => {
  let _number = number

  if (typeof _number === 'string') {
    _number = Number(_number)
  }

  if (_number && (typeof _number === 'number' || isNaN(_number))) {
    return _number.toLocaleString()
  }
  return _number
}

export const toNumberFixed = (number: number | string, fixed: number = 2) => {
  let _number = number

  if (typeof _number === 'undefined' || !_number) {
    return '-'
  }

  if (typeof _number === 'string') {
    _number = Number(_number)
  }

  if (_number && (typeof _number === 'number' || isNaN(_number))) {
    return _number.toLocaleString(undefined)
  }
  return _number.toLocaleString(undefined)
}

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const downloadFile = ({
  blob,
  filename,
}: {
  blob: Blob
  filename?: string
}) => {
  const a = document.createElement('a')
  let objectUrl = window.URL.createObjectURL(blob)

  a.href = objectUrl
  a.download = `${moment().format('YYYYMMDDHHMMss')}_${filename}.xlsx`
  a.click()

  return blob
}

export function downloadCSV({
  text,
  filename,
}: {
  text: string
  filename: string
}) {
  const dataStr = 'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute(
    'download',
    `${moment().format('YYYYMMDDHHMMss')}_${filename}.csv`
  )
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()

  return text
}

export function downloadAsJson({
  json,
  filename,
}: {
  json: object
  filename: string
}) {
  const dataStr =
    'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(json))
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute('download', filename + '.json')
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()

  return json
}

export function loadImage(value: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.responseType = 'blob'
    xhr.open('GET', `${apiBaseUrl}/maintain/images/${value}`)
    xhr.setRequestHeader(
      'authorization',
      Store.get('tclight_token') as NonNullable<string>
    )
    xhr.send()

    xhr.onload = () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        const objectURL = URL.createObjectURL(xhr.response)
        if (objectURL) {
          return resolve(objectURL)
        }
      }
    }

    xhr.onerror = error => reject(error)
  })
}

const getDatePart = (date: Date) => {
  const M = date.getMonth() + 1 // getMonth() is zero-based
  const dd = date.getDate()
  const hh = date.getHours()
  const mm = date.getMinutes()
  const ss = date.getSeconds()

  return [
    date.getFullYear(),
    (M > 9 ? '' : '0') + M,
    (dd > 9 ? '' : '0') + dd,
    (hh > 9 ? '' : '0') + hh,
    (mm > 9 ? '' : '0') + mm,
    (ss > 9 ? '' : '0') + ss,
  ]
}

export function dateToYYYYMMDD(date?: Date, sep: string = '-') {
  if (!date) {
    return date
  }

  const [yyyy, mm, dd] = getDatePart(date)

  return [yyyy, mm, dd].join(sep)
}
