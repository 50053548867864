import React from 'react'
import { Router } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { ApolloProvider } from '@apollo/react-hooks'
import zhTW from 'antd/lib/locale/zh_TW'
import { ThemeProvider } from 'styled-components'
import createClient from '../graphql/client'
import theme from '../theme'
import history from '../utils/history'
import { AuthProvider, AuthContext } from '../hooks/useAuth'

const renderer = (App: React.FunctionComponent) => {
  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider locale={zhTW}>
        <AuthProvider>
          <AuthContext.Consumer>
            {value => (
              <ApolloProvider client={createClient(value)}>
                <Router history={history}>
                  <App />
                </Router>
              </ApolloProvider>
            )}
          </AuthContext.Consumer>
        </AuthProvider>
      </ConfigProvider>
    </ThemeProvider>
  )
}

export default renderer
