import { css } from 'styled-components'
import { normalize } from 'polished'
import { keys, map, compose } from 'ramda'
import { MARGINS, DIRECTS } from './constants/margins'

const utils = css`
  ${map(
    m =>
      compose(
        map(
          d =>
            css`
              .${d}-${m} {
                ${DIRECTS[d]}: ${m}px !important;
              }
            `
        ),
        keys
      )(DIRECTS),
    MARGINS
  )};

  ${map(
    (w: number) => css`
      .w${w} {
        width: ${w === 100 ? '100%' : w === 50 ? '50%' : w + 'px'} !important;
      }

      .h${w} {
        height: ${w === 100 ? '100%' : w + 'px'} !important;
      }

      .min-width--${w} {
        min-width: ${w}px !important;
      }
    `,
    MARGINS
  )}

  ${p =>
    map(
      (x: string | any) => css`
        .text-${x} {
          color: ${p.theme[x]} !important;
        }

        .bg-${x} {
          background: ${p.theme[x]};
        }
      `,
      keys(p.theme)
    )};

  .text--danger {
    color: #f5222d !important;
  }

  .text-larger {
    font-size: 16px !important;
  }

  .text-500 {
    font-weight: 500 !important;
  }

  .text-default {
    font-size: 14px !important;
  }

  .text-sm {
    font-size: 12px;
  }

  .top-5 {
    top: 5px;
  }

  .w47 {
    width: 47% !important;
  }

  .w48 {
    width: 48% !important;
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .text-underline {
    text-decoration: underline;
  }

  .text-white {
    color: #fff;
  }

  .bg-light {
    background: #fffcf1;
  }

  .bg-white {
    background: #fff;
  }

  .flex-1 {
    flex: 1;
  }

  .pull-left {
    float: left;
  }

  .pull-right {
    float: right;
  }

  .p--relative {
    position: relative;
  }

  .is--scrollbar-none {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .block {
    display: block;
  }

  .b--bottom {
    border-bottom: 1px solid #e8e8e8 !important;
  }

  .width--full {
    width: 100%;
  }

  .height--full {
    height: 100%;
  }
`

const layout = css`
  .l-flex {
    display: flex !important;

    &--center {
      display: flex;
      align-items: center;
    }

    &.is--stretch {
      align-items: stretch;
    }

    &.is--center {
      justify-content: center;
    }

    &.is--space-between {
      justify-content: space-between;
    }

    &.is--right {
      justify-content: flex-end;
    }

    &.is--middle {
      align-items: center;
    }

    &.is--space-between {
      justify-content: space-between;
    }

    &.is--column {
      flex-direction: column;
    }
  }

  .d-inline-block {
    display: inline-block !important;
  }
`

const components = css`
  .c-datepicker {
    .ant-input:hover,
    .ant-input:focus,
    &.ant-calendar-picker:hover
      .ant-calendar-picker-input:not(.ant-input-disabled) {
      border-color: ${p => p.theme.primary};
      box-shadow: 0 0 0 2px ${p => p.theme.warning};
    }

    &.border-bottom--only input {
      box-shadow: none;
      border: none;
      border-bottom: 1px solid #d9d9d9;
    }

    &.has-error .ant-input,
    &.has-error .ant-input:hover {
      border: 1px solid #f5222d !important;
    }
  }

  .c-btn.ant-btn {
    border-radius: 3px;
    font-weight: 500;
  }

  .c-btn--primary.ant-btn {
    color: ${p => p.theme.darkGrey};
    border-color: ${p => p.theme.primary};
    background: ${p => p.theme.primary};

    &:hover,
    &:focus,
    &:active {
      color: ${p => p.theme.darkGrey};
      background: #efb237;
      border-color: #efb237;
    }
  }

  .c-btn--outline.ant-btn {
    color: ${p => p.theme.secondary};
    border: 1.3px solid ${p => p.theme.secondary};
    background: #fff;

    &:hover,
    &:focus,
    &:active {
      color: ${p => p.theme.secondary};
      background: #fff6e4;
      border: 1.3px solid ${p => p.theme.secondary};
    }
  }

  .c-btn--danger.ant-btn {
    color: ${p => p.theme.danger};
    border: 1.3px solid ${p => p.theme.danger};
    background: #fff;

    &:hover,
    &:focus,
    &:active {
      color: ${p => p.theme.danger};
      background: ${p => p.theme.lightPink};
      border: 1.3px solid ${p => p.theme.danger};
    }
  }

  .c-icon {
    &.icon-loading {
      svg {
        height: 16px;
        width: 16px;
      }
    }

    &.icon-location,
    &.icon-eye,
    &.icon-edit,
    &.icon-process,
    &.icon-info {
      cursor: pointer;
    }

    &.icon-location:hover,
    &.icon-process:hover {
      svg > g > path:nth-child(2) {
        fill: ${p => p.theme.primary};
      }
    }

    &.icon-eye:hover,
    &.icon-edit:hover {
      svg use {
        fill: ${p => p.theme.primary};
      }
    }

    &.icon-info:hover {
      svg > g path:nth-child(3) {
        fill: ${p => p.theme.primary};
      }
    }

    &.anticon:hover {
      svg {
        fill: ${p => p.theme.primary};
      }
    }

    &.is--secondary {
      &.icon-eye {
        svg use {
          fill: ${p => p.theme.secondary};
        }
      }
    }
  }

  .c-link {
    color: #1890ff;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #40a9ff;
    }
  }

  .c-select__control {
    min-height: 30px !important;
    line-height: 0;
    box-shadow: none !important;
    border-color: #d9d9d9 !important;
    cursor: pointer !important;

    &:focus,
    &:hover {
      border-color: ${p => p.theme.primary} !important;
    }

    .anticon svg {
      transition: transform 0.3s;
    }
  }

  .c-select__control--menu-is-open {
    .anticon svg {
      transform: rotate(180deg);
    }
  }

  .c-select__indicators {
    margin-right: -3px;
  }

  .c-select__indicator {
    color: hsl(0, 0%, 80%);

    svg {
      margin-right: 2px;
      height: 18px;
      width: 18px;
    }
  }

  .c-select__indicator-separator {
    width: 0 !important;
  }

  .c-select__placeholder {
    color: #bfbfbf !important;
  }

  .c-select__value-container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .c-select__menu {
    z-index: 2 !important;
    margin-top: 2px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
  }

  .c-select__menu-list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .c-select__single-value {
    color: rgba(0, 0, 0, 0.65) !important;
    overflow: visible !important;
  }

  .c-select__option {
    padding-top: 5.5px !important;
    padding-bottom: 5.5px !important;
    line-height: 21px;
    cursor: pointer !important;
  }

  .c-select__option:not(&.c-select__option--is-focused) {
    background-color: #fff !important;
    color: rgba(0, 0, 0, 0.65) !important;
  }

  .c-select__option--is-focused {
    background-color: ${p => p.theme.warning} !important;
    color: rgba(0, 0, 0, 0.65) !important;
  }

  div[class*='indicatorContainer'] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
  }
`

const antdOverride = css`
  @media (max-width: 575px) {
    .ant-form-item-label,
    .ant-form-item-control-wrapper {
      width: auto !important;
    }
  }

  .ant-calendar-date:hover {
    background: ${p => p.theme.warning} !important;
  }

  .ant-calendar-header a:hover {
    color: ${p => p.theme.primary} !important;
  }

  .ant-calendar-today .ant-calendar-date {
    color: ${p => p.theme.darkGrey} !important;
    border-color: ${p => p.theme.primary} !important;
  }

  .ant-calendar-selected-day .ant-calendar-date {
    background: ${p => p.theme.warning} !important;
  }

  .ant-calendar-today-btn {
    color: ${p => p.theme.dark};

    &:hover {
      color: ${p => p.theme.darkGrey};
    }
  }

  .ant-calendar-selected-date .ant-calendar-date,
  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
    background: ${p => p.theme.primary} !important;
    color: ${p => p.theme.darkGrey} !important;
  }

  .ant-calendar-range .ant-calendar-in-range-cell::before {
    background: ${p => p.theme.warning} !important;
  }

  .ant-calendar-header a.ant-calendar-month-panel-month:hover {
    color: ${p => p.theme.darkGrey} !important;
    background: ${p => p.theme.warning} !important;
  }

  .ant-select-dropdown-menu-item-active {
    background: ${p => p.theme.warning} !important;
  }

  .ant-message-notice-content {
    box-shadow: none !important;
  }

  .ant-icon-is-hoverable {
    cursor: pointer;

    &:hover {
      color: ${p => p.theme.secondary};
    }
  }

  .ant-toolitp-content {
    font-weight: 500 !important;
  }

  .ant-tooltip-arrow:before {
    background: ${p => p.theme.primary} !important;
  }

  .ant-tooltip-inner {
    background: ${p => p.theme.warning} !important;
    border: 1px solid ${p => p.theme.primary} !important;
    color: ${p => p.theme.darkGrey} !important;
    box-shadow: none !important;
  }

  .ant-modal {
    animation-duration: 0s !important;
    transition: none !important;
  }

  .ant-spin-dot-item {
    background: ${p => p.theme.primary} !important;
  }

  .ant-form-item-no-colon {
    .ant-form-item-required::before {
      display: none !important;
    }
  }

  .ant-input-group-compact {
    .ant-select-selection {
      background-color: #fafafa !important;
      border-radius: 4px 0px 0px 4px !important;
    }
    .ant-input {
      border-radius: 0px 4px 4px 0px !important;
    }
  }
`

const googleMapsOverride = css`
  .gm-style-cc {
    display: none;
  }

  .gm-style a[href^="https://maps.google.com/maps"]
  {
    display: none !important;
  }

  .infoBox > img {
    display: none;
  }

  .infoBox:before {
    content: ' ';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    top: -10px;
    left: 130px;
  }
`

const gisMapOverride = css`
  .ol-popup .ol-popup-closer {
    font-size: 18px !important;
    color: rgb(74, 74, 74) !important;
  }

  .ol-popup {
    position: absolute;
    filter: drop-shadow(0 1px 4px px rgba(0, 0, 0, 0.2));
    bottom: 11px;
    left: -50px;
    padding: 30px 8px 20px !important;
    background-color: rgb(255, 255, 255) !important;
    border-radius: 4px !important;
    border: 1px solid rgb(255, 194, 71) !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: rgb(74, 74, 74) !important;
    min-width: 310px !important;
  }

  .ol-popup .ol-popup-closer {
    font-size: 18px !important;
    color: rgb(74, 74, 74) !important;
  }

  .ol-popup:before {
    border-top-color: rgb(255, 194, 71) !important;
  }

  .popup-col-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 26.5px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    font-family: Roboto !important;
  }

  .popup-col {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 26.5px;
    color: #4a4a4a;
    font-weight: 500 !important;
    font-size: 12px !important;
    font-family: Roboto !important;
  }

  .popup-col:nth-child(even) span {
    background: rgb(255, 252, 241);
  }

  .popup-col:nth-child(odd) span {
    background: rgb(255, 241, 184);
  }

  .popup-col span,
  .popup-col-header span {
    flex: 1;
    text-align: left;
    padding: 5px 8px;
  }

  .popup-col-header span {
    background: rgb(182, 129, 22);
  }

  .popup-col span:nth-child(even),
  .popup-col-header span:nth-child(even) {
    margin-left: 8px;
  }

  .popup-light-header {
    padding: 3px 8px;
    background: rgb(74, 74, 74);
    font-size: 14px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    text-align: left;
    border-bottom: 1px solid #fff;
  }

  #newsBar {
    display: none !important;
  }

  .ol-popup .more {
    line-height: 26.5px;
    text-align: right !important;
    font-weight: 700;
    vertical-align: middle;
  }
  svg.map-box {
    filter: drop-shadow(30px 10px 4px red);
  }
`

export default css`
  ${normalize};
  ${utils};
  ${layout};
  ${components};
  ${googleMapsOverride}
  ${antdOverride};
  ${gisMapOverride}
`
