import { createGlobalStyle, css } from 'styled-components'
import globalStyles from 'shared/global-styles'
import { isMobile } from 'shared/env'

export default createGlobalStyle`
  ${globalStyles};

  ${
    isMobile() &&
    css`
      #root {
        overflow-y: hidden;
      }
    `
  }

  .ant-row.is--mobile {
    padding-left: 20px;
    padding-right: 20px;
  }

  .ant-col.table-wrapper.is--mobile {
    overflow-x: scroll;
    width: 960px;
    padding-right: 20px;
  }

  .ant-select {
    .ant-select-selection {
      border-radius: 2px;
      border-color: ${p => p.theme.grey};
    }
  }

  .ant-calendar-picker-input.ant-input {
    height: 36px;
    border-radius: 2px;
    border-color: ${p => p.theme.grey};
  }

  .ant-select:not(.ant-select-sm) {
    .ant-select-selection--single {
      height: 36px !important;
    }

    .ant-select-selection__rendered {
      line-height: 36px !important;
    }
  }

  .ant-table {
    .ant-table-thead > tr > th {
      padding: 6px 16px !important;
      background: #feefd2;
      border-bottom: none !important;
      color: ${p => p.theme.darkGrey} !important;
    }

    .ant-table-content {
      border: 1px solid ${p => p.theme.lightGrey};
    }
  }

  .ant-table-pagination.ant-pagination {
    .ant-pagination-item a {
      padding: 0 8px !important;
      font-weight: 500 !important;
    }

    .ant-pagination-item:not(:hover):not(.ant-pagination-item-active) a {
      color: ${p => p.theme.darkGrey} !important;
    }
  }

  .ant-table-wrapper {
    .ant-pagination {
      display: flex !important;
      justify-content: center !important;
      float: none !important;
      align-items: center !important;
      margin-top: 30px !important;
    }

    &.is--mobile .ant-pagination {
      justify-content: flex-start !important;
      margin-left: 60px;
    }

    .ant-pagination-item:hover a {
      color: ${p => p.theme.primary} !important;
    }

    .ant-pagination-item-active {
      border-color: transparent !important;
      background: transparent !important;
    }

    .ant-pagination-item-active a {
      color: ${p => p.theme.primary} !important;
    }
  }

  .ant-input {
    height: 36px !important;
    border-radius: 2px !important;
    border: 1px solid ${p => p.theme.grey} !important;
  }

  textarea.ant-input {
    border-radius: 2px;
    border: 1px solid ${p => p.theme.grey};
  }

  .c-btn {
    min-width: 120px !important;
    border-radius: 22px !important;
    font-weight: 500 !important;

    &.is--green {
      border-color: #43f3b7;
      background: #43f3b7 !important;
      color: ${p => p.theme.dark} !important;

      &:active,
      &:focus,
      &:hover {
        border-color: #43f3b7;
        background: #63f3b7 !important;
      }
    }

    &.is--yellow {
      border-color: ${p => p.theme.primary};
      background: ${p => p.theme.primary} !important;
      color: ${p => p.theme.dark} !important;

      &:active,
      &:focus,
      &:hover {
        color: ${p => p.theme.dark} !important;
        border-color: ${p => p.theme.primary};
        background: ${p => p.theme.primary} !important;
      }
    }

    &.is--pink {
      border-color: #ffa287;
      background: #ffa287 !important;
      color: ${p => p.theme.dark} !important;

      &:active,
      &:focus,
      &:hover {
        border-color: #ffa287;
        background: #ffa287 !important;
      }
    }

    &.is--dark {
      border-color: ${p => p.theme.dark};
      background: ${p => p.theme.dark} !important;
      color: #fff !important;

      &:active,
      &:focus,
      &:hover {
        color: #fff;
        border-color: ${p => p.theme.dark};
        background: ${p => p.theme.dark} !important;
      }
    }
  }
`
