import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { onError, ErrorResponse } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { RestLink } from 'apollo-link-rest'
import logger from 'apollo-link-logger'
import Store from 'store'
import {
  path as Rpath,
  compose,
  ifElse,
  pathOr,
  anyPass,
  includes,
  isNil,
  invoker,
  identity,
} from 'ramda'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { AuthContextType } from '../hooks/useAuth'
import message, { destroy } from '../utils/message'
import camelCase from '../utils/camelcase'
import { envStore, isEnvDev } from '../env'
import { downloadFile, downloadCSV } from '../utils/webHelper'

const contentTypeIncludes = (s: string) => {
  return compose(
    ifElse(isNil, identity, (c = '') => c.includes(s)),
    invoker(1, 'get')('content-type'),
    Rpath(['headers']),
  )
}

const createClient = ({ logout, isLoggedOut }: AuthContextType) => {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [], // no types provided
      },
    },
  })

  const restLink = new RestLink({
    uri: envStore.apiBaseUrl,
    endpoints: { geocoding: envStore.googleMapsGeocodingApiBaseUrl },
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    fieldNameNormalizer: (key: string) => camelCase(key),
    responseTransformer: async (response: Response, typeName) => {
      if (!response || !response.headers) {
        return response
      }

      try {
        if (contentTypeIncludes('sheet')(response)) {
          const blob = await response.blob()
          return downloadFile({ blob, filename: typeName })
        }

        if (contentTypeIncludes('text/csv')(response)) {
          const text = await response.text()
          return downloadCSV({ text, filename: typeName })
        }

        return response.json().then(json => json)
      } catch (error) {
        console.warn(`[Transformer Error]: ${error}`)
        return {}
      }
    },
    bodySerializers: {
      form: (data: any, headers: Headers) => {
        const formData = new FormData()
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            formData.append(key, data[key])
          }
        }

        headers.delete('Content-Type')

        return { body: formData, headers }
      },
    },
  })

  const authLink = setContext((_, { headers }) => {
    const token = Store.get('tclight_token')
    // get the authentication token from cookies if it exists
    const authHeaders = compose(
      ifElse(
        isNil,
        () => ({}),
        () => ({
          authorization: token,
        }),
      ),
    )(token)
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        ...authHeaders,
      },
    }
  })

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation }: ErrorResponse) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.warn(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        )
      if (networkError) {
        console.warn(`[Network error]: ${networkError}`)

        const statusCode = Rpath(['statusCode'], networkError)

        const silent = pathOr(
          false,
          ['variables', 'params', 'silent'],
          operation,
        )

        const isExpired = compose(
          anyPass([
            includes('JWT expired'),
            includes('無法解析 token，請確認 token 是否合法或過期'),
          ]),
          pathOr('', ['result', 'msg']),
        )(networkError)

        if (
          !isExpired &&
          (statusCode === 500 || statusCode === 400) &&
          !silent
        ) {
          message({
            content:
              Rpath(['result', 'message'], networkError) ||
              Rpath(['result', 'msg'], networkError) ||
              '伺服器錯誤',
            type: 'error',
            top: 50,
          })
        }

        if (isExpired && !isLoggedOut) {
          logout({
            onCompleted: () => {
              destroy()
              message({
                content: '登入已過期，請重新登入',
                type: 'error',
                top: 100,
                maxCount: 1,
              })
            },
          })
        }
      }
    },
  )

  return new ApolloClient({
    link: ApolloLink.from([
      ...(isEnvDev ? [logger] : [logger]),
      authLink,
      errorLink,
      restLink,
    ]),
    cache: new InMemoryCache({ fragmentMatcher }),
    connectToDevTools: true,
  })
}

export default createClient
