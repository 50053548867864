export const HOME = '/'
export const REPAIR_INQUIRY = '/repair-inquiry'
export const STREETLIGHT = '/street-light'
// export const STREETLIGHT_DETAIL = `${STREETLIGHT}/:id`
export const DISPATCH = '/dispatch'
// export const DIAPTCH_DETAIL = `${DISPATCH}/:id`
export const NEW_REPAIR = '/new-repair'
export const ADOPTION = '/adoption'
export const LINKS = '/links'
export const OPEN_DATA = '/open-data'
