import React, { useEffect, useState } from 'react'
import Bowser from 'bowser'
import { Modal } from 'antd'
import Icon from 'shared/components/Icon'
import styled from 'styled-components'

import Button from 'shared/components/Button'

function CheckBrowserSupport() {
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent)
    const current = browser.getBrowser()
    const isIE = current.name.match('Internet Explorer') //Internet Explorer

    setVisible(isIE ? true : false)
  }, [])

  return (
    <StyleModal
      title={null}
      visible={visible}
      closable={false}
      footer={<Button onClick={() => setVisible(c => !c)}>OK</Button>}>
      <Icon
        type="info-circle"
        style={{
          fontSize: '24px',
          color: '#faad14',
          verticalAlign: 'middle',
          marginRight: 5,
        }}
      />
      <p>
        本網站不支援Internet Explorer瀏覽器, 建議使用其他瀏覽器(例如：Chrome,
        Edage(最新版), ...)
      </p>
    </StyleModal>
  )
}

const StyleModal = styled(Modal)`
  .ant-modal-content {
    background-color: #fff;
  }
  .ant-modal-body {
    display: flex;
    font-size: 14px;
  }
  .ant-modal-close-icon {
    color: ${p => p.theme.info};
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    border-top: none;
    padding: 0 62px 32px 54px;
  }
`

export default CheckBrowserSupport
